import React from "react";
import { useState } from "react";
import { Icon, useToast, Toggle, Title } from "../../";
import { MultiOptionList, Option, OptionList } from ".";
import { ComponentSection } from "../../../v2/09-routes/ComponentExamplesRoute/ComponentSection";

const options: Option[] = [
  { key: "Option 1" },
  { title: "Option 2", key: "l2", endAdornment: <Icon name="calendar" /> },
  { key: "Option 3", disabled: true }
];

const optionsAdornments = [
  { key: "Option 1", endAdornment: <Icon name="calendar" /> },
  { title: "Option 2", key: "l2", endAdornment: <Icon name="files" /> },
  { key: "Option 3", disabled: true, endAdornment: <Icon name="trigger" /> }
];

const categorizedOptions = [
  { key: "Option 1", category: "Options" },
  { key: "Option 2", category: "Options" },
  { key: "Example 1", category: "Examples" },
  { key: "Option 3", category: "Options" },
  { key: "Example 2", category: "Examples" },
  { key: "Option 4", category: "Options" },
  { key: "Option 5", category: "Options" },
  { key: "Option 6", category: "Options" },
  { key: "Option 7", category: "Options" },
  { key: "Option 8", category: "Options" }
];

export const OptionListExamples = () => {
  const toast = useToast();

  const [selected, setSelected] = useState<string>();
  const [multiSelected, setMultiSelected] = useState<string[]>([]);

  const handleItemClick = (option: Option) => {
    const exists = multiSelected.includes(option.key);
    if (!exists) setMultiSelected([...multiSelected, option.key]);
    else setMultiSelected([...multiSelected.filter(_ => _ !== option.key)]);
  };

  const onRemove = () => alert("Removed");

  const [highlightSearchMatch, setHighlighSearchMatch] = useState(false);

  const [dynamicOptions, setDynamicOptions] = useState([{ key: "1" }, { key: "2" }]);

  return (
    <>
      <ComponentSection
        title="Basic"
        description="Option list have two variants: default and blended"
      >
        <OptionList
          options={options}
          selected={selected}
          onItemClick={option => setSelected(option.key || option.title)}
        />
        <OptionList
          options={optionsAdornments}
          selected={selected}
          onItemClick={option => setSelected(option.key || option.title)}
          variant="blended"
        />
      </ComponentSection>
      <ComponentSection title="Max height" description="Control option list max height">
        <Title>200px max height</Title>
        <OptionList
          options={categorizedOptions}
          selected={selected}
          onItemClick={option => setSelected(option.key || option.title)}
          maxHeight="200px"
        />
        <Title>500px max height</Title>
        <OptionList
          options={categorizedOptions}
          selected={selected}
          onItemClick={option => setSelected(option.key || option.title)}
          maxHeight="500px"
        />
      </ComponentSection>
      <ComponentSection title="Categories" description="">
        <OptionList
          options={categorizedOptions}
          selected={selected}
          onItemClick={option => setSelected(option.key || option.title)}
        />
        <OptionList
          options={categorizedOptions.slice(0, 3)}
          selected={selected}
          onItemClick={option => setSelected(option.key || option.title)}
          variant="blended"
        />
      </ComponentSection>
      <ComponentSection title="Actions" description="">
        <OptionList
          options={optionsAdornments}
          selected={selected}
          onItemClick={option => setSelected(option.key || option.title)}
          actions={[
            {
              key: "Add new Item",
              startAdornmentIcon: "plus",
              onClick: () => toast.info("Adding new item")
            }
          ]}
        />
        <OptionList
          options={[]}
          selected={selected}
          onItemClick={option => setSelected(option.key || option.title)}
          actions={[
            {
              key: "Add new Item",
              startAdornmentIcon: "plus",
              onClick: () => toast.info("Adding new item")
            }
          ]}
        />
      </ComponentSection>
      <ComponentSection title="Search" description="">
        <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
          <Toggle
            checked={highlightSearchMatch}
            onChange={checked => setHighlighSearchMatch(checked)}
          />
          <Title>Highlight search match</Title>
        </div>
        <OptionList
          searchable
          highlightSearchMatch={highlightSearchMatch}
          searchPlaceholder="Search or add new"
          onEnter={search => toast.info(`Enter with value ${search}`)}
          options={optionsAdornments}
          selected={selected}
          onItemClick={option => setSelected(option.key || option.title)}
        />
        <OptionList
          searchable
          highlightSearchMatch={highlightSearchMatch}
          searchPlaceholder="Search or add new"
          onEnter={search => toast.info(`Enter with value ${search}`)}
          variant="blended"
          options={optionsAdornments}
          selected={selected}
          onItemClick={option => setSelected(option.key || option.title)}
        />
      </ComponentSection>
      <ComponentSection title="Multiselect" description="">
        <MultiOptionList options={options} selected={multiSelected} onItemClick={handleItemClick} />
        <MultiOptionList
          options={optionsAdornments}
          selected={multiSelected}
          onItemClick={handleItemClick}
        />
      </ComponentSection>
      <ComponentSection title="Blended with indicator" description="">
        <OptionList
          variant="blended"
          showIndicator={true}
          options={optionsAdornments}
          selected={selected}
          onItemClick={option => setSelected(option.key || option.title)}
        />
      </ComponentSection>
      <ComponentSection title="Adornments" description="">
        <OptionList
          options={[
            { key: "katy.introist@gmail.com", onRemove, startAdornment: <Icon name="person" /> },
            { key: "janice.introist@gmail.com", onRemove, startAdornment: <Icon name="person" /> },
            {
              key: "Introist Meeting room",
              onRemove,
              startAdornment: <Icon name="calendar" />,
              endAdornment: <Icon name="info" />
            },
            {
              key: "resource.013590395801935-introist@introist.resources.com",
              onRemove,
              startAdornment: <Icon name="calendar" />,
              endAdornment: <Icon name="info" />
            }
          ]}
          selected={selected}
          onItemClick={option => setSelected(option.key || option.title)}
        />
        <OptionList
          options={[
            {
              key: "katy.introist@gmail.com",
              onEdit: () => {},
              startAdornment: <Icon name="person" />
            },
            {
              key: "janice.introist@gmail.com",
              onEdit: () => {},
              startAdornment: <Icon name="person" />
            },
            {
              key: "Introist Meeting room",
              onEdit: () => {},
              startAdornment: <Icon name="calendar" />,
              endAdornment: <Icon name="info" />
            },
            {
              key: "resource.013590395801935-introist@introist.resources.com",
              onEdit: () => {},
              startAdornment: <Icon name="calendar" />,
              endAdornment: <Icon name="info" />
            }
          ]}
          selected={selected}
          onItemClick={option => setSelected(option.key || option.title)}
        />
        <OptionList
          options={[
            {
              key: "katy.introist@gmail.com",
              onEdit: () => {},
              onRemove,
              startAdornment: <Icon name="person" />
            },
            {
              key: "janice.introist@gmail.com",
              onEdit: () => {},
              onRemove,
              startAdornment: <Icon name="person" />
            },
            {
              key: "Introist Meeting room",
              onEdit: () => {},
              onRemove,
              startAdornment: <Icon name="calendar" />,
              endAdornment: <Icon name="info" />
            }
          ]}
          selected={selected}
          onItemClick={option => setSelected(option.key || option.title)}
        />

        <OptionList
          options={dynamicOptions}
          searchable
          clearSearchOnOptionsChange
          onItemClick={option => setDynamicOptions([{ key: "3" }, { key: "4" }])}
          actions={
            dynamicOptions.some(o => o.key === "3")
              ? [{ key: "Back", onClick: () => setDynamicOptions([{ key: "1" }, { key: "2" }]) }]
              : undefined
          }
        />
      </ComponentSection>
    </>
  );
};
