import React, { useState, MouseEvent } from "react";
import { IconButton, IconName, OptionList, OptionListProps, Popover } from "../..";
import styles from "./ActionMenu.module.scss";
import cx from "classnames";

export type ActionMenuProps = OptionListProps & {
  attachToRef?: boolean;
  icon?: IconName;
  onOpen?: (e: MouseEvent<HTMLButtonElement>) => unknown;
};

const asPromise = (func: () => unknown): Promise<unknown> => {
  return Promise.resolve(func());
};

export const ActionMenu = ({
                             options,
                             className,
                             style,
                             icon = "more",
                             attachToRef = true,
                             onOpen,
                             ...rest
                           }: ActionMenuProps) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [menuRef, setMenuRef] = useState<HTMLDivElement | null>(null);

  const loadingAwareOnClickOptions = options.map(option => ({
    ...option,
    onClick: async () => {
      if (!option.onClick) return;

      setLoading(true);
      setOpen(false);
      asPromise(option.onClick).finally(() => {
        setLoading(false);
      });
    }
  }));

  return (
    <div className={cx(styles.ActionMenu, className)} style={style} ref={setMenuRef}>
      <IconButton icon={icon} onClick={(e) => {
        setOpen(!open);
        onOpen && onOpen(e);
      }} loading={loading} />

      <Popover
        referenceElement={menuRef}
        onClose={() => {
          setOpen(false);
        }}
        open={open}
        attachToRef={attachToRef}
      >
        <OptionList {...rest} options={loadingAwareOnClickOptions} />
      </Popover>
    </div>
  );
};
