import _ from "lodash";
import React, { CSSProperties, ReactNode, useEffect, useRef, useState } from "react";
import {
  Caps2,
  Card,
  ICardProps,
  Icon,
  IconName,
  Input,
  OptionListItem,
  P4,
  Title,
  useTheme
} from "../../";
import styles from "./OptionList.module.scss";
import { v4 as uuidV4 } from "uuid";

export interface OptionListProps extends Omit<OptionListBaseProps, "selectable" | "selected"> {
  selected?: string;
}

export const OptionList = ({ selected, ...rest }: OptionListProps) => {
  return <OptionListBase selected={selected ? [selected] : []} scrollToSelected {...rest} />;
};

export interface MultiOptionListProps extends Omit<OptionListBaseProps, "selectable"> {}

export const MultiOptionList = (props: MultiOptionListProps) => {
  return <OptionListBase {...props} selectable />;
};

export interface Option {
  key: string;
  title?: string;
  titleStyles?: CSSProperties;
  category?: string;
  disabled?: boolean;
  startAdornment?: React.ReactNode;
  startAdornmentIcon?: IconName;
  endAdornment?: React.ReactNode;
  onClick?: () => unknown;
  onRemove?: () => unknown;
  onEdit?: () => unknown;
  onMouseEnter?: VoidFunction;
  onMouseLeave?: VoidFunction;
}

interface OptionListBaseProps extends Omit<ICardProps, "onSelect"> {
  variant?: "default" | "blended";
  options: Option[];
  onItemClick?: (selected: Option) => unknown;
  onEnter?: (search: string) => unknown;
  onFilterOptions?: (search: string, options: Option[]) => Option[];
  selected?: string[];
  selectable?: boolean;
  markSelected?: boolean;
  showIndicator?: boolean;
  scrollToSelected?: boolean;
  searchable?: boolean;
  searchPlaceholder?: string;
  actions?: Option[];
  autoFocus?: boolean;
  description?: string;
  highlightSearchMatch?: boolean;
  clearSearchOnOptionsChange?: boolean;
  maxHeight?: string;
}

export const OptionListBase = ({
  options,
  onItemClick,
  onEnter,
  onFilterOptions,
  selected,
  selectable,
  markSelected,
  showIndicator,
  scrollToSelected,
  searchable,
  searchPlaceholder,
  actions,
  variant = "default",
  autoFocus = false,
  highlightSearchMatch = false,
  description,
  clearSearchOnOptionsChange = false,
  maxHeight = "250px",
  ...rest
}: OptionListBaseProps) => {
  const { theme } = useTheme();
  const selectedRef = useRef<HTMLButtonElement>(null);

  const [search, setSearch] = useState("");

  useEffect(() => {
    if (selectedRef.current && scrollToSelected) {
      selectedRef.current.scrollIntoView({ block: "center" });
    }
  }, []);

  useEffect(() => {
    if (clearSearchOnOptionsChange) setSearch("");
  }, [options]);

  const matchWithSearch = (option: Option) => {
    const optionValue = (option.title || option.key)?.toLowerCase().trim();
    const searchValue = search.toLowerCase().trim();

    return optionValue.includes(searchValue);
  };

  const getFilteredOptions = () => {
    if (onFilterOptions) return onFilterOptions(search, options);
    if (searchable) return options.filter(matchWithSearch);
    return options;
  };

  const filteredOptions = getFilteredOptions();
  const groupedOptions = _.groupBy(filteredOptions, "category");
  const groups = Object.keys(groupedOptions);

  const buildDivider = (group: string) =>
    group !== "undefined" ? <OptionListDivider category={group} /> : null;

  const buildOptionListItem = (option: Option) => {
    const isSelected = selected?.includes(option.key);

    const key = `${option.key}-${uuidV4()}`;

    const startAdornment =
      option.startAdornment ||
      (option.startAdornmentIcon && <Icon name={option.startAdornmentIcon} />);
    return (
      <OptionListItem
        search={highlightSearchMatch ? search : undefined}
        key={key}
        ref={isSelected ? selectedRef : undefined}
        labelStyles={option.titleStyles}
        label={option.title || option.key}
        selected={isSelected}
        onClick={() => {
          option.onClick && option.onClick();
          onItemClick && onItemClick(option);
        }}
        onMouseEnter={option.onMouseEnter}
        onMouseLeave={option.onMouseLeave}
        onRemove={option.onRemove}
        onEdit={option.onEdit}
        disabled={option.disabled}
        startAdornment={startAdornment}
        endAdornment={option.endAdornment}
        selectable={selectable}
        markSelected={markSelected}
        showIndicator={showIndicator !== undefined ? showIndicator : variant === "default"}
      />
    );
  };

  const optionItemsWithDivider = _.flatten(
    groups.map(group => {
      const divider = buildDivider(group);
      const groupItems = groupedOptions[group];
      const optionListItems = groupItems.map(buildOptionListItem);
      return [divider, ...optionListItems];
    })
  );

  const actionItems = actions?.map(action => {
    const startAdornment =
      action.startAdornment ||
      (action.startAdornmentIcon && (
        <Icon name={action.startAdornmentIcon} color={theme.palette.primary.default} />
      ));

    return (
      <OptionListItem
        disableHoverStyle
        key={action.key}
        label={action.title || action.key}
        onClick={() => {
          action.onClick && action.onClick();
        }}
        startAdornment={startAdornment}
        endAdornment={action.endAdornment}
        labelStyles={{
          color: theme.palette.primary.default,
          fontWeight: 500
        }}
      />
    );
  });

  const renderSearch = () => {
    if (!searchable) return null;

    return (
      <Input
        value={search}
        onChange={setSearch}
        onKeyPress={e => {
          if (e.key === "Enter" && onEnter) {
            onEnter(search);
          }
        }}
        className={styles.Search}
        style={{
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          border: "none",
          borderBottom: variant !== "blended" ? "1px solid" : "none",
          borderBottomColor: theme.palette.border.subdued
        }}
        active={false}
        placeholder={searchPlaceholder}
        variant="blended"
        autoFocus={autoFocus}
        scrollToInputOnAutoFocus={false}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
        disableHover
        endAdornment={!!search && onEnter ? <Icon name="return" shortcut /> : null}
      />
    );
  };

  const renderNoOptions = () => {
    const message = searchable ? "No matching options" : "No options";
    return (
      <Title color={theme.palette.foreground.dimmed} style={{ padding: theme.spacing.small }}>
        {message}
      </Title>
    );
  };

  const descriptionComponent = (
    <div className={styles.Description}>
      <P4>{description}</P4>
    </div>
  );
  const createComponent = (
    <OptionListItem onClick={() => onEnter!(search)} label={`Create: ${search}`} />
  );

  const hasActiveCreate = !!search && onEnter;

  if (variant === "blended") {
    return (
      <div style={{ position: "relative" }} {...rest}>
        {renderSearch()}
        {description ? descriptionComponent : null}
        {optionItemsWithDivider}
        {optionItemsWithDivider.length === 0 && !hasActiveCreate && renderNoOptions()}
        {hasActiveCreate && createComponent}
        {actionItems}
      </div>
    );
  }

  return (
    <Card className={styles.Root} elevated {...rest}>
      {renderSearch()}
      <div className={styles.OptionList} style={{maxHeight}}>
        {description ? descriptionComponent : null}
        {actionItems}
        {optionItemsWithDivider}
        {optionItemsWithDivider.length === 0 && !hasActiveCreate && renderNoOptions()}
        {hasActiveCreate && createComponent}
      </div>
    </Card>
  );
};

const OptionListDivider = ({ category }: { category: string }) => {
  return (
    <div className={styles.Category}>
      <div className={styles.Divider} />
      <Caps2 size="semibold">{category}</Caps2>
      <div className={styles.Divider} />
    </div>
  );
};
